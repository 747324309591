import { default as React, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import './App.css';

import { pickBy, compact, isEmpty } from 'lodash';
import qs from 'qs'
import useSwr from 'swr';
import hhmmss from 'hhmmss';

import { makeStyles, styled, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import BasePaper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';

import BaseAlert, { AlertProps } from '@material-ui/lab/Alert';
import ytdl, { filterFormats } from 'ytdl-core';
import { FormControl, InputLabel, MenuItem, Select, FormControlLabel, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import { makeForm } from './lib/form/hooks/use-form';
import Joi from 'joi';

/**
 *
 */
const Alert = (props: AlertProps) => {
  return <BaseAlert elevation={6} variant="filled" {...props} />;
}

/**
 *
 */
interface Message {
  severity: AlertProps['severity'],
  content: string
}

const Paper = styled(BasePaper)({
  padding: '2rem',
  width: '100%'
});

/**
 *
 */
const useStyles = makeStyles({
  root: {},
  input: {
    '&:not(:last-child)': {
      marginBottom: '1rem'
    }
  },
  accordion: {
    marginBottom: '1rem'
  },
  accordionDetails: {
    display: 'block',
    '& > *:not(:last-child)': {
      marginBottom: '1rem'
    }
  },
  formControl: {
    marginBottom: '1rem',
  },
  container: {
  },
  card: {
    marginBottom: '1rem'
  },
  thumbnail: {
    height: '150px'
  }
});

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

const DURATION_REGEX = /^((\d{1,2}:)?[0-5]?[0-9]:)?[0-5]?[0-9]$/;

const useForm = makeForm({
  url: Joi.string().uri().required(),
  startTime: Joi.string().allow('').regex(DURATION_REGEX).optional(),
  endTime: Joi.string().allow('').regex(DURATION_REGEX).optional(),
  downloadType: Joi.string().allow('both', 'video', 'audio').required(),
  format: Joi.number().required(),
});

function App() {
  const styles = useStyles();

  const { formData, update, errors, userErred, errorMessage } = useForm({
    url: '',
    startTime: '',
    endTime: '',
    downloadType: 'both',
    format: 0,
  });

  const [ messages, setMessages ] = useState<Message[]>([]);
  const [ downloading, setDownloading ] = useState(false);

  const urlInputRef = useRef<HTMLInputElement>(null);

  /**
   *
   */
  const openVideo = useCallback(() => {
    window.open(formData.url, '_blank');
  }, [formData]);

  /**
   *
   */
  const removeMessage = (index: number) =>
    setMessages([
      ...messages.slice(0, index),
      ...messages.slice(index + 1)
    ]);

  /**
   *
   */
  const showMessage = useCallback(
    (message: Message) => setMessages([...messages, message]),
    [setMessages, messages]
  );

  const { data, isValidating: isFetchingVideo, mutate: refresh, error } = useSwr(
    formData.url && !errors.url
      ? formData.url
      : null,
    async url => {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/info?url=${encodeURIComponent(formData.url)}`);
      return response.json() as Promise<Pick<ytdl.videoInfo, 'videoDetails' | 'formats'>>;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      initialData: null
    }
  );

  /**
   *
   */
  const downloadVideo = useCallback(async () => {
    showMessage({severity: 'info', content: 'The video will download shortly!'});

    window.open(data?.formats[formData.format].url, '_blank');
  }, [formData, showMessage, data?.formats]);

  /**
   *
   */
  const hasVideo = useCallback(
    (): boolean => !!data && !isFetchingVideo && !error,
    [data, isFetchingVideo, error]
  );

  /**
   *
   */
  const canDownload = useCallback(
    () => hasVideo() && isEmpty(errors),
    [hasVideo, errors]
  );

  /**
   *
   */
  const getFormatDescription = useCallback(
    (format: ytdl.videoFormat) => compact([
      format.qualityLabel,
      format.audioBitrate ? `${format.audioBitrate}kbps` : undefined,
    ]).join('@'),
    []
  );

  const formats = useMemo(() => data?.formats.filter(format => {
    if (format.container !== 'mp4') return false;

    switch (formData.downloadType) {
      case 'both':
        return format.hasAudio && format.hasVideo;
      case 'video':
        return format.hasVideo && !format.hasAudio;
      case 'audio':
        return format.hasAudio && !format.hasVideo;
    }
  }) || [], [data?.formats, formData.downloadType]);

  console.log(formData);

  /**
   *
   */
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && canDownload()) downloadVideo();
    };

    document.addEventListener('keypress', listener);

    return () => document.removeEventListener('keypress', listener);
  }, [canDownload, downloadVideo]);

  return <MuiThemeProvider theme={theme}>
    <Container className={styles.root} maxWidth="sm">
      <Box
        display='flex'
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        minWidth='100%'
      >
        <Paper className={styles.container}>
          <TextField
            inputRef={urlInputRef}
            error={userErred('url')}
            helperText={errorMessage('url')}
            className={styles.input}
            label="Video URL"
            fullWidth
            variant="outlined"
            onBlur={update.url}
            onChange={update.url}
            disabled={isFetchingVideo || downloading}
            defaultValue={formData.url}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {isFetchingVideo ?
                  <CircularProgress color="secondary" />
                :
                  <IconButton onClick={() => refresh()} style={{display: hasVideo() ? '' : 'none' }}>
                    <RefreshIcon />
                  </IconButton>
                }
              </InputAdornment>
            }}
           ></TextField>

          {hasVideo() ?
            <Box>
              <Card className={styles.card} variant="outlined">
                <CardActionArea onClick={e => openVideo()}>
                  <CardMedia
                    className={styles.thumbnail}
                    image={`https://i.ytimg.com/vi/${data?.videoDetails.videoId}/hqdefault.jpg`}
                    title={data?.videoDetails.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {data?.videoDetails.title}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      {hhmmss(parseInt(data?.videoDetails.lengthSeconds || '0'))}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {data?.videoDetails.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>

              <Accordion className={styles.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography>Options</Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                  <TextField
                    error={userErred('startTime')}
                    helperText={errorMessage('startTime')}
                    label="Start time (optional)"
                    placeholder="Eg. 00:00:05"
                    fullWidth
                    defaultValue={formData.startTime}
                    disabled={downloading}
                    onChange={update.startTime}
                   ></TextField>
                  <TextField
                    error={userErred('endTime')}
                    helperText={errorMessage('endTime')}
                    label="End time (optional)"
                    placeholder="Eg. 00:00:10"
                    fullWidth
                    defaultValue={formData.endTime}
                    disabled={downloading}
                    onChange={update.endTime}
                   ></TextField>
                </AccordionDetails>
              </Accordion>

              <FormControl className={styles.formControl} component="fieldset" fullWidth margin="normal">
                <FormLabel component="legend">Download Type</FormLabel>
                <RadioGroup
                  aria-label="download-type"
                  name="download-type"
                  value={formData.downloadType}
                  onChange={e => {
                    update.downloadType(e);
                    update.format.directly(0);
                  }}
                >
                  <FormControlLabel value="both" control={<Radio />} label="Audio & Video" />
                  <FormControlLabel value="video" control={<Radio />} label="Video Only" />
                  <FormControlLabel value="audio" control={<Radio />} label="Audio Only" />
                </RadioGroup>
              </FormControl>

              <FormControl
                className={styles.formControl}
                fullWidth
                margin="normal"
              >
                <InputLabel id="demo-simple-select-label">Quality</InputLabel>
                <Select
                  error={userErred('format')}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.format}
                  onChange={update.format}
                >
                  {formats.map((format, i) =>
                    <MenuItem key={i} value={i}>
                      {getFormatDescription(format)}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              {/* <List dense>
                {data?.formats.map((format, i) => {
                  const labelId = `checkbox-list-secondary-label-${i}`;
                  return (
                    <ListItem key={i} button>
                      <ListItemIcon>
                        {format.hasVideo && <Movie />}
                        {format.hasAudio && <VolumeUp />}
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`${data?.videoDetails.title} (${format.qualityLabel})`} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List> */}

              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                endIcon={downloading ? <CircularProgress color="secondary" size="1rem" /> : <GetAppIcon />}
                disabled={!canDownload() || downloading}
                onClick={e => downloadVideo()}
              >
                Download
              </Button>
            </Box>
            :
            null
          }
        </Paper>
      </Box>

      {messages.map((message, i) =>
        <Snackbar key={i} open autoHideDuration={6000} onClose={e => removeMessage(i)}>
          <Alert severity={message.severity}>{message.content}</Alert>
        </Snackbar>
      )}
    </Container>
   </MuiThemeProvider>
}

export default App;
